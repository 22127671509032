<template>
  <transition name="dropdown" appear>
    <div
      style="display: grid; gap: 1rem; height: 100%; align-content: flex-start"
      class="container-xs"
    >
      <div style="display: grid; grid-template-columns: 1fr auto">
        <h2 class="app-title">
          {{ $t('profile.my') }}
          <span class="block">{{ $t('profile.stickers') }}</span>
        </h2>
        <x-close-button style="margin-top: 0.5rem" />
      </div>
      <break style="width: 100%" />
      <div
        style="display: grid; grid-template-columns: 1fr 1fr; overflow-x: none"
      >
        <img
          v-for="(sticker, index) in stickers"
          :key="sticker.id"
          :src="$image(sticker.imageUrl)"
          class="sticker"
          alt="Sticker"
          :style="stickerStyles(index)"
          @click="clickHandler(sticker)"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Stickers',
  methods: {
    clickHandler(sticker) {
      this.$store.commit('StickerModule/setActiveSticker', sticker)
      this.$router.push('/profile/stickers/details')
    },
    getRand: multiplier => Math.round((Math.random() * 2 - 1) * multiplier),
    stickerStyles(index) {
      return {
        transform: `rotate(${index ? this.getRand(20) : 20}deg)`,
        left: `${index ? this.getRand(20) : 0}px`,
        top: `${index ? this.getRand(20) : -30}px`,
      }
    },
  },
  computed: mapState('StickerModule', ['stickers']),
}
</script>

<style scoped lang="scss">
.sticker {
  max-width: 155px;
  max-height: 200px;
  object-fit: contain;
  object-position: center;
  align-self: center;
  cursor: pointer;
  position: relative;
}
.x-title {
  line-height: 1.1;
  font-size: 4.375rem;
}
</style>
